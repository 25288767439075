import React, { useState } from 'react';
import AppRouter from './Router';

const App = () => {
  const [main, setMain] = useState(true);

  return (
      <AppRouter/>
  );
};

export default App;