export function FooterMain() {
    return (
    <section className="bg-light text-center">
        <h2 className="d-none">hidden</h2>
        <div className="container">
            <div className="row">
                {/* <div className="col-md-4">
                    <div className="footer-social">
                        <ul className="list-unstyled">
                            <li><a className="wow fadeInUp" href="https://www.facebook.com/optimozinc"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                            <li><a className="wow fadeInDown" href="https://twitter.com/optimoz1"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                            <li><a className="wow fadeInDown" href="https://www.linkedin.com/company/optimozinc"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div> */}
                <div className="col-md-6">
                    <a href="https://optalk.ai/privacy/">Privacy Policy</a>&nbsp;|&nbsp;
                    <a href="https://optalk.ai/terms/">Terms of Service</a>&nbsp;|&nbsp;
                    {/* <a href="https://app.optalk.ai/docs/">Support</a> */}
                </div>
                <div className="col-md-6">
                    <p className="company-about fadeIn">&copy; 2024 Optimoz, Inc.</p>
                </div>
            </div>
        </div>
    </section>
    );
}