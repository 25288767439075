import React from 'react';
import { LoaderMain } from '../../components/AppComponents/LoaderMain';
import NavbarMain from '../../components/AppComponents/NavbarMain';
import { VideoMain } from '../../components/AppComponents/VideoMain';
import { About } from '../../components/AppComponents/About';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import Aixmoz from '../../components/AppComponents/Aixmoz';
import ProductsServices from '../../components/AppComponents/ProductsServices';
import { Architecture } from '../../components/AppComponents/Architecture';
import { Careers } from '../../components/AppComponents/Careers';
import OptalkCarousel from '../../components/AppComponents/OptalkCarousel';

const HomePage = () => {
  return (
    <div>
        <LoaderMain/>
        <NavbarMain/>
        {/* <VideoMain/> */}
        <About/>
        <Aixmoz/>
        {/* <BlogMain/> */}
        <ProductsServices/>
        <Architecture/>
        {/* <TeamMain/> */}
        {/* <Portfolio/>
        <Ebook/> */}
        <Careers/>
        <OptalkCarousel/>
        <ContactInfo/>
        <FooterMain/>
    </div>
  );
};

export default HomePage;