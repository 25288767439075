export function Architecture() {
    return (
        <section id="architecture">
            <div className="container-fluid bg-black">
                <div className="container wow fadeInUp">
                    <img src="images/optalk/optalk-integration.png"/>
                </div>
            </div>
        </section>
    );
}