// intial state for user
const initialState = {
    data: {},
    loading: true,
    error: false,
};

// user reducer to handle action
const userReducer = (state = initialState, action) => {
    switch (action.type) {
    case 'LOAD_USER':
        return { ...state, data: {}, error: false, loading: true };
    case 'GET_USER':
        return state;
    case 'SET_USER':
        return { ...state, data: action.payload, error: false, loading: false };
    case 'ERROR_USER':
        return { ...state, data: {}, error: true, loading: false };
    default:
        return state;
    }
};

export default userReducer;
