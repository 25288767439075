export function About() {
    return (
        <section id="about">
            <div className="container wow fadeInUp">
            <div className="row">
                    <div className="col-md-12 text-center wow fadeIn">
                        <div className="title d-inline-block">
                            <h6 className="mb-3">optalk transforms the way you work</h6>
                            <h2 className="gradient-text1 mb-3">optalk: AI Assisted data analytics</h2>
                        </div>
                    </div>
            </div>

                {/* About */}
                <div className="row align-items-center">
                    <div className="col-md-12">
                    optalk is an advanced AI-assisted data analytics platform designed to empower organizations with data-driven decision-making capabilities. One of its standout features is its open architecture, which allows seamless integration with a variety of third-party applications, ensuring that businesses can easily incorporate optalk into their existing workflows. The platform supports a wide range of data connectivity options, ranging from unstructured text to structured databases, enabling comprehensive analysis across diverse data sources. Additionally, optalk prioritizes security, privacy, and good AI governance, ensuring that all data is handled with the utmost care and in compliance with industry standards. This robust combination of flexibility, connectivity, and stringent data protection makes optalk an invaluable tool for enterprises aiming to enhance their business operations and customer experiences through the latest technological advancements.
                    </div>
                </div>
                <div className="row align-items-center mt-3">
                    <div className="col-md-12">
                        The key benefits for your enterprise include:<br />

                        <span className="item-number gradient-text1">(1)</span>
                        Breakthrough insights to improve decision-making.<br />
                        <span className="item-number gradient-text1">(2)</span>
                        Better productivity and business agility.<br />
                        <span className="item-number gradient-text1">(3)</span>
                        Faster, more predictable solution development.<br />
                        <span className="item-number gradient-text1">(4)</span>
                        Increased systems and application performance and reliability.<br />
                        <span className="item-number gradient-text1">(5)</span>
                        Integrated security that protects at the infrastructure, platform, and application layers.<br />
                        <span className="item-number gradient-text1">(6)</span>
                        Reduced operating costs and enterprise risk.<br />
                        <br />
                        <strong className="gradient-text1">Our Approach</strong> Is based on proven best practices in artificial intelligence, data management, software engineering, DevSecOps, cloud engineering, and technology management.
                    </div>
                </div>
            </div>

            <div id="capabilities" className="container-fluid bg-black mt-3">
                <div className="container wow fadeInUp">
                    <img src="images/optalk/optalk-features.png"/>
                </div>
            </div>
        </section>
    );
}