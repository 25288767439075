import React, { useState, useEffect } from 'react';
import axios from 'axios';

export function Careers() {
    return (
        <section id="careers" className="career-section">
            <div className="container wow fadeInUp">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="title d-inline-block">
                            <h6 className="mb-3">Embark on a Rewarding Career Journey.</h6>
                            <h2 className="gradient-text1 mb-3">Careers</h2>
                        </div>
                    </div>
                    <div className="col-md-6 wow fadeInLeft">
                        <h2 className="red-header gradient-text1">Dream High - Achieve More</h2>
                        <p>optalk is a service of Optimoz. At Optimoz, we are passionate about delivering innovative solutions that enable our clients to improve their ability to deliver cutting-edge applications. We welcome dreamers. Our clients include federal and state agencies and mid-to-large sized corporations. Optimoz is growing fast, and we’re looking for qualified professionals to join our team and grow with us. We are based in North Bethesda, MD. If you have a solid foundation in software engineering, DevOps, IT security, or Project Management and are looking to leverage those skills to the fullest, Optimoz is the place to be at. In addition to diverse, meaningful work opportunities, we support employees in pursuing training, technical certifications, and continuing education opportunities to stay ahead.
                        <a href="https://optimoz.com/careers/" target="_new">Check out our current openings at Optimoz's career page at https://optimoz.com/careers/</a></p>
                    </div>
                    <div className="col-md-6 wow fadeInRight">
                        <h3 className="gradient-text1 mb-3">Benefits</h3>
                        <p>We offer competitive salary and benefits. Our benefits package includes:</p>
                        <ul className="list2">
                            <li>Health Insurance and Prescription Plan</li>
                            <li>Paid Time-Off (PTO)</li>
                            <li>Holidays</li>
                            <li>Training assistance for personal and professional growth.</li>
                        </ul>
                        <p>Optimoz is an Equal Employment Opportunity Employer. Qualified applicants will receive consideration for employment regardless of race, color, religion, sex, age, national origin, marital status, disability, veteran status, sexual orientation, genetic information, or any other protected status.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}