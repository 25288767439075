import React from 'react';
// import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';

export default function OptalkCarousel() {
  return (
    <section id="optalk-carousel" className="bg-black">
    <div className='container container-fluid bg-black wow fadeInUp'>
      <Carousel>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100" src="/images/optalk/1-upload.gif"
            alt="Upload a file"
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100" src="/images/optalk/2-select-multiple.gif"
            alt="Select multiple files"
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100" src="/images/optalk/3-split.gif"
            alt="Split session"
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100" src="/images/optalk/4-workspace.gif"
            alt="Workspace"
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100" src="/images/optalk/5-templates.gif"
            alt="Templates"
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
    </section>
  );
}