export default function ProductsServices() {
    return (
        <section id="features" className="bg-light">
            <div className="container">
                {/* Heading */}
                <div className="row">
                    <div className="col-md-12 text-center wow fadeIn">
                        <div className="title d-inline-block">
                            <h6 className="mb-3">optalk transforms the way you work</h6>
                            <h2 className="gradient-text1 mb-3">optalk key features</h2>
                            <p>optalk delivers the experience and expertise you need to leverage advances in AI and data management.</p>
                        </div>
                    </div>
                </div>

                {/* Products */}
                <div className="row">
                    <div id="price-slider" className="owl-carousel owl-item wow fadeInUp">
                        {/* price item */}
                        <div className="price-item item text-start">
                            <h4 className="alt-font gradient-text1 d-inline-block font-weight-500 mb-4">Secure and Private</h4>
                            <div className="price-tag d-flex align-items-top" style={{minHeight: '155px'}}>
                                <div className="price alt-font text-dark-gray">
                                    <h3 className="currency"><i className="fa fa-lock" aria-hidden="true"></i></h3>
                                </div>
                                <p className="price-text no-margin">High performance secure solutions built on our proprietary AI framework and industry-leading generative AI technologies.</p>
                            </div>
                            {/* <ul className="packages">
                                <li><i className="fa fa-check" aria-hidden="true"></i>Full access</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Unlimited Bandwidth</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Powerful Admin Panel</li>
                                <li><i className="fa fa-times" aria-hidden="true"></i>Email Accounts</li>
                                <li><i className="fa fa-times" aria-hidden="true"></i>8 Free Forks Every Months</li>
                            </ul>
                            <a href="javascript:void(0);" className="btn btn-large btn-gradient btn-rounded w-100">Learn More</a> */}
                        </div>
                        {/* price item */}
                        <div className="price-item text-start">
                            <h4 className="alt-font d-inline-block font-weight-500 mb-4">Open Architecture</h4>
                            <div className="price-tag d-flex align-items-top" style={{minHeight: '155px'}}>
                                <div className="price alt-font text-dark-gray">
                                    <h3 className="currency"><i className="fa fa-gears" aria-hidden="true"></i></h3>
                                </div>
                                <p className="price-text no-margin">Modern open application architecture with API first strategy that speed the development pipeline, enhance operations, and increase security.</p>
                            </div>
                            {/* <ul className="packages">
                                <li><i className="fa fa-check" aria-hidden="true"></i>Full access</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Unlimited Bandwidth</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Powerful Admin Panel</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Email Accounts</li>
                                <li><i className="fa fa-times" aria-hidden="true"></i>8 Free Forks Every Months</li>
                            </ul>
                            <a href="javascript:void(0);" className="btn btn-large btn-black btn-rounded w-100">Learn More</a> */}
                        </div>
                        {/* price item */}
                        <div className="price-item text-start">
                            <h4 className="alt-font gradient-text1 d-inline-block font-weight-500 mb-4">Wide Data Coverage</h4>
                            <div className="price-tag d-flex align-items-top" style={{minHeight: '155px'}}>
                                <div className="price alt-font text-dark-gray">
                                    <h3 className="currency"><i className="fa fa-database" aria-hidden="true"></i></h3>
                                </div>
                                <p className="price-text no-margin">optalk supports a wide range of data types, including unstructured text data from Microsoft Word, PDF, Excel, and plain text, as well as structured data in SQL or NoSQL databases such as Oracle, MySQL, PostgreSQL, SQL Server, and MongoDB.</p>
                            </div>
                            {/* <ul className="packages">
                                <li><i className="fa fa-check" aria-hidden="true"></i>Full access</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Unlimited Bandwidth</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Powerful Admin Panel</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Email Accounts</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>8 Free Forks Every Months</li>
                            </ul>
                            <a href="javascript:void(0);" className="btn btn-large btn-gradient btn-rounded w-100">Learn More</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}